export const codeWeights = {
  J: '00',
  SB1: '10',
  SB2: '20',
  SB3: '30',
  SB4: '40',
  SB5: '50',
  SB6: '60',
  SPA: '10',
  SPB: '11',
  SPC: '12',
  SU: '20',
  SUA: '21',
  SUB: '22',
  SUC: '23',
  TP1: '11',
  TP2: '12',
  TP3: '42',
  TP4: '43',
  TP5: '61',
  TP6: '62',
  SP1: '11',
  SP2: '12',
  SP3: '42',
  SP4: '43',
  SP5: '61',
  SP6: '62',
  AU: '50',
  AUA: '51',
  AUB: '60',
  FA: '50',
  FAA: '51',
  FAB: '60',
  FAC: '70',
};
