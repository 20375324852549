import React, { useState, useEffect } from 'react';
import { loginRequest } from '../authConfig';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, Redirect } from 'react-router-dom';

import axios from 'axios';

import { InteractionRequiredAuthError, InteractionStatus, BrowserAuthError, EventType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { callMsGraph } from '../graph';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
const LoginSSO = () => {
  const state = useSelector((state) => state);
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [apiData, setApiData] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();

  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  instance.addEventCallback(
    (event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        instance.setActiveAccount(account);
      }
    },
    (error) => {
      console.log('error', error);
    }
  );

  instance
    .handleRedirectPromise()
    .then((authResult) => {
      // Check if user signed in
    })
    .catch((err) => {
      // TODO: Handle errors
      console.log(err);
    });

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const account = instance.getActiveAccount();
      if (!account) {
        console.log('no account', inProgress);
        instance.loginRedirect();
      } else {
        console.log('account', account);
        const accessTokenRequest = {
          scopes: ['user.read'],
          account: account,
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            callGraphAPI(accessToken);
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.error(error);
          });
      }
    }

    // handle auth redired/do all initial setup for msal
    //
  }, [inProgress]);

  const callGraphAPI = (accessToken) => {
    callMsGraph(accessToken).then((response) => {
      console.log('Setting Graph Data ', response);

      setApiData(response);
      axios({
        method: 'POST',
        data: {
          email: response.mail.toLowerCase(),
          name: response.displayName,
        },
        url: '/SSOLogin',
      })
        .then((res) => {
          console.log('Res', res);
          dispatch({
            type: 'SET_USER',
            payload: res.data.user,
          });
          // history.push('/report');
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  // useEffect(() => {
  //   const accessTokenRequest = {
  //     scopes: ['user.read'],
  //     account: accounts[0],
  //   };
  //   if (!apiData && inProgress === InteractionStatus.None) {
  //     instance
  //       .acquireTokenSilent(accessTokenRequest)
  //       .then((accessTokenResponse) => {
  //         // Acquire token silent success
  //         let accessToken = accessTokenResponse.accessToken;
  //         callGraphAPI(accessToken);
  //       })
  //       .catch((error) => {
  //         if (error instanceof InteractionRequiredAuthError) {
  //           instance.acquireTokenRedirect(accessTokenRequest);
  //         }
  //         console.error(error);
  //       });
  //   }
  // }, [instance, accounts, inProgress, apiData]);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     handleLogin();
  //   }
  // }, []);

  const handleLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then(() => {
        console.log('then', loginRequest);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (state?.auth) {
    return <Redirect to='/report' />;
  }

  return null;

  return !isAuthenticated ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <button
        style={{
          backgroundColor: '#0078d4',
          color: '#fff',
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={handleLogin}
      >
        Login
      </button>
    </div>
  ) : null;
};

export default LoginSSO;
