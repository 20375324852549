import React from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <div className='progress-bar-container'>
      <div className='pie' style={{ '--p': progress }}>
        {progress}%
      </div>
    </div>
  );
};

export default ProgressBar;
