/*
    This file contains helper functions for updating the state of the
    Scorecard component.

    The functions are:

    updateStandards 
        - updates the standards array in the state
        - input: reportData, standardId, field, value
        - output: updated standards array

    updateStandardsCriteria 
        - updates the criteria array in the state
        - input: reportData, standardId, criteriaId, field, value
        - output: updated reportData

    translateScore 
        - translates the score to float
        - input: score
        - output: float
        
    calculateAverageScore
        - calculates the average score for each standard
        - input: reportData
        - output: an object with the average score for each standard

    isValidScore
        - checks if the score is valid
        - input: score
        - output: boolean
*/
export const updateStandardsCriteria = (reportData, standardId, criteriaId, field, value) => {
  return reportData.standards.map((std, idx) => {
    if (idx === standardId) {
      return {
        ...std,
        criteria: std.criteria.map((crt, idx) => {
          if (idx === criteriaId) {
            return {
              ...crt,
              [field]: value,
            };
          }
          return crt;
        }),
      };
    }
    return std;
  });
};

export const updateStandards = (reportData, standardId, field, value) => {
  return reportData.standards.map((std, idx) => {
    if (idx === standardId) {
      return {
        ...std,
        [field]: value,
      };
    }
    return std;
  });
};

export const translateScore = (score) => {
  if (isNaN(score)) return 0;
  return Number.parseFloat(score);
};

export const calculateAverageScore = (reportData) => {
  return reportData?.standards?.reduce((prev, curr, index) => {
    return {
      ...prev,
      [index]:
        curr?.userscore ||
        parseFloat(
          (
            curr?.criteria?.reduce((p, c, i) => {
              return p + c.score;
            }, 0) / curr?.criteria?.length
          ).toFixed(2)
        ),
    };
  }, {});
};

export const isValidScore = (score) => {
  // Check if score is a number
  if (typeof score !== 'number') {
    return false;
  }
  // Check if score is within the range of 1 to 3
  if (score < 1 || score > 3) {
    return false;
  }
  // Check if score is in 0.1 increments
  if ((score * 10) % 1 !== 0) {
    return false;
  }
  return true;
};

export const intToRoman = (num) => {
  // convert int to roman numeral : range 1-10
  const roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  return roman[num - 1];
};

export const renderStandardTitle = (num, title) => {
  return title.trim().toLowerCase().startsWith('standard') ? title : `Standard ${intToRoman(num + 1)}: ${title}`;
};
