import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCE = ({ value, onChange, height = 300, disabled, id }) => {
  const editorRef = useRef(null);

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={value}
      init={{
        height: height,
        menubar: false,
        force_br_newlines: true,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | image link | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
      onBlur={(e) => {
        onChange(e.target.getContent());
      }}
      disabled={disabled}
      id={id}
    />
  );
};

export default TinyMCE;
